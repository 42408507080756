<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" class="create-ticket-content" slot="component">
      <p class="create-ticket-content-title">{{ $t("create new ticket") }}</p>
      <form @submit.prevent="createTicket">
        <div class="form-group">
          <div class="error-container" v-if="formError">
            <p class="error">{{ $t(formError) }}</p>
          </div>

          <!-- CATEGORY -->
          <div class="form-control">
            <label for="category">{{ $t("choose category") }}</label>
            <select
              ref="categoryDropdown"
              class="drop-layout"
              :class="{ 'padding-right': localDisplayLang !== 'eng' }"
              v-model="category"
              @change="changeTitle(category)"
            >
              <!-- <option disabled value>{{ $t("choose category") }}</option> -->
              <!-- <option selected value="Technical">{{ $t("technical") }}</option> -->
              <!-- <option value="Relational">{{ $t("relational") }}</option> -->
              <option v-for="(categoryItem, index) in categoriesList" :key="index" :value="categoryItem.apiCategory">{{
                localDisplayLang === "ara" ? categoryItem.categoryTitle.ar : categoryItem.categoryTitle.en
              }}</option>
            </select>
          </div>

          <!-- TITLE -->

          <div class="form-control">
            <label for="title">
              {{ $t("title") }}
              <span style="color:red;">*</span>
            </label>
            <input type="text" autocomplete="off" v-model="title" id="title" />
          </div>
          <!-- <div class="form-control">
            <label for="category">{{ $t("choose category") }}</label>
            <select
              ref="categoryDropdown"
              class="drop-layout"
              :class="{ 'padding-right': localDisplayLang !== 'eng' }"
              v-model="category"
            >
              <option v-for="(categoryItem, index) in categoriesList" :key="index" :value="categoryItem.apiCategory">{{
                localDisplayLang === "ara" ? categoryItem.categoryTitle.ar : categoryItem.categoryTitle.en
              }}</option>
            </select>
          </div> -->
          <div class="form-control">
            <label for="description">
              {{ $t("message") }}
              <span style="color:red;">*</span>
            </label>
            <textarea id="description" v-model="description" rows="4" cols="50" maxlength="256"></textarea>
            <span class="charVal" :class="[localDisplayLang === 'eng' ? 'default' : 'flipped']">
              <span id="desc-chars">{{ maxChars }}</span> {{ $t("characters") }}
            </span>
          </div>
        </div>
        <div class="create-ticket-content-upload">
          <input
            type="file"
            accept="image/jpeg, image/png"
            class="file-input"
            :value="uploadedFileVal"
            @change="attachFile($event)"
          />
          <p class="upload">{{ $t("Attach Doc") }}</p>
        </div>
        <div class="create-ticket-attachments" v-if="imagesUploadedArr.length !== 0">
          <div class="create-ticket-attachments-file" v-for="(image, index) in imagesUploadedArr" :key="index">
            <div class="create-ticket-attachments-file-details">
              <!-- <div class="create-ticket-attachments-file-details-image">
                <img :src="image.pic" alt />
              </div> -->
              <div class="create-ticket-attachments-file-details-name">
                <span class="create-ticket-attachments-file-name">{{ image.name }}</span>
              </div>
            </div>
            <img class="delete-file" src="@/assets/icons/ClosePopup.svg" @click="deleteAttachment(image)" alt />
          </div>
        </div>

        <div class="create-ticket-footer">
          <button
            class="button-primary submit"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            type="submit"
          >
            <span class="btn-txt-color">{{ $t("create") }}</span>
          </button>

          <div v-if="showLoader">
            <Loading></Loading>
          </div>
        </div>
      </form>
    </div>
  </Popup>
</template>

<script>
import snackbar from "node-snackbar";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import { _providerId } from "@/provider-config.js";

import Utility from "@/mixins/Utility";
import { showSnackBar } from '../../../utilities';

export default {
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  props: {
    closePopup: {
      type: Function,
    },
  },

  mixins: [Utility],

  data() {
    return {
      width: "32%",
      margin: "10% auto",
      radiusType: "full",
      title: "",
      category: null,
      description: "",
      formError: null,
      attachments: [],
      fileAttachments: [],
      imageUrl: null,
      showLoader: false,
      maxChars: 256,
      uploadedFileVal: null,
      providerUniqueId: _providerId,

      imageFileUploadPayload: null,
      imagesUploadedArr: [],
      attachmentedFiles: [],
      localDisplayLang: null,

      categoriesList: null,
      defaultSelectedCategory: null,
    };
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.imagesUploadedArr = [];

    this.categoriesList = this.appConfig.ticketCategories;
  },

  mounted() {
    eventBus.$on("create-ticket-error", (value) => {
      console.log("THE ERROR ->", value);
      this.formError = value;
    });

    eventBus.$on("create-ticket-success", () => {
      // snackbar.show({
      //   text: "Ticket Successfully Created",
      //   pos: "bottom-center",
      // });

      showSnackBar(this.$t("Ticket Successfully Created"));

      this.$emit("refreshList");

      this.closePopup();
    });

    this.category = this.categoriesList[0].apiCategory;

    if (this.localDisplayLang === "eng") {
      this.title = this.categoriesList[0].categoryTitle.en;
    } else if (this.localDisplayLang === "ara") {
      this.title = this.categoriesList[0].categoryTitle.ar;
    }
  },

  computed: {
    ...mapGetters(["subscriberid", "appConfig"]),
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 2000);
      }
    },

    attachmentedFiles(val) {
      if (val && val.length > 0) {
        if (this.imagesUploadedArr.length === val.length) {
          let payload = {
            title: this.title,
            message: this.description,
          };

          if (this.category) {
            payload.category = this.category;
          }

          if (this.attachmentedFiles.length) {
            payload.filename = JSON.stringify(this.attachmentedFiles);
          }

          console.log("The files are attached successfully, Payload ===>", payload);
          // this.createTicket(true);
          eventBus.$emit("create-ticket", payload);
          this.showLoader = false;
        }
      }
    },

    description(val) {
      document.getElementById("desc-chars").innerHTML = 256 - val.length;
    },
  },

  methods: {
    deleteAttachment(value) {
      let getIndex = this.imagesUploadedArr.findIndex((el) => el.name === value.name);
      console.log("Delete file index", getIndex);
      if (getIndex > -1) {
        this.imagesUploadedArr.splice(getIndex, 1);
      }
    },

    changeTitle(val) {
      console.log("THE FUNCTION TRIGGRED", val);

      this.categoriesList.forEach((element, index) => {
        console.log("THE VALUE -------", val);
        if (element.apiCategory === val) {
          if (this.localDisplayLang === "eng") {
            this.title = this.categoriesList[index].categoryTitle.en;
          } else if (this.localDisplayLang === "ara") {
            this.title = this.categoriesList[index].categoryTitle.ar;
          }
        }
      });
    },

    uploadImageAttachments() {
      if (this.imagesUploadedArr && this.imagesUploadedArr.length > 0) {
        console.log("The image file upload func is called");

        this.imagesUploadedArr.forEach((image, index) => {
          let fileObj = {
            isTicket: true,
            index: index,
            params: {
              filename: image,
            },
          };
          console.log(`The ${index} upload call ---> `, fileObj);

          eventBus.$emit("fileUpload", fileObj);

          eventBus.$on(`file-upload-response-${index}`, (data) => {
            console.log("THE UPLOAD ERROR ->", data);

            if (data.reason) {
              this.showLoader = false;
              this.formError = this.$t(data.reason);
            } else {
              this.attachmentedFiles.push(data.success);
              console.log("the response for " + index + " ----->", data);
            }
            eventBus.$off(`file-upload-response-${index}`);
          });
        });
      }
    },

    createTicket() {
      console.log("THE SELECT CHECK -- TITLE --", this.title);

      console.log("THE SELECT CHECK -- CATEGORY --", this.category);

      console.log("THE SELECT CHECK -- DESC --", this.description);

      if (!this.title && !this.description) {
        this.formError = "Title and Description is required";
        return false;
      }

      if (!this.validateFormField(this.title, "title") || !this.validateFormField(this.description, "desc")) {
        return;
      }

      if (!this.showLoader) {
        this.showLoader = true;

        let payload = {
          title: this.title,
          message: this.description,
        };

        if (this.category) {
          payload.category = this.category;
        }

        if (this.imagesUploadedArr && this.imagesUploadedArr.length > 0) {
          console.log("Image is present, hence calling image upload first");
          this.uploadImageAttachments();
        } else {
          console.log("Image is not present, hence calling create directly");
          eventBus.$emit("create-ticket", payload);
          this.showLoader = false;
        }
      }
    },

    validateFormField(value, type) {
      this.formError = null;

      const newValue = value.trim();

      if (!newValue && type === "title") {
        this.formError = this.$t("Please enter Title");
        return false;
      }

      if (!newValue && type === "desc") {
        this.formError = this.$t("Please enter Description");
        return false;
      }

      if (newValue.length > 64 && type === "title") {
        this.formError = this.$t("Title can not be more than 64 characters");
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.padding-right {
  padding-right: 35px;
}

.create-ticket {
  &-content {
    padding: 10%;
    &-title {
      color: #c1c1c1;
      font-family: $font-regular;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 29px;
      margin-bottom: 20px;
    }
    .charVal {
      font-size: 0.7rem;
      color: #5c5c5d;
      font-family: $font-regular;

      &.default {
        float: right;
      }

      &.flipped {
        float: left;
      }
    }
    .drop-layout {
      -webkit-appearance: none;
      background: url("../../../assets/icons/down_arrow.svg") no-repeat 98%;
      line-height: 1rem;
      color: #efeff4;
      &:focus {
        background-color: #000000;
      }
    }
    &-upload {
      position: relative;
      margin-top: 1rem;
      .file-input {
        position: absolute;
        opacity: 0;
        z-index: 100;
        width: 28%;
        height: 24px;
      }
      .upload {
        width: 28%;
        padding: 6px 2px;
        color: #5c5c5d;
        font-family: $font-regular;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 11px;
        text-align: center;
        background: $clr-dark-gd1;
        border: 1px solid $clr-popup-label;
        border-radius: 2px;
      }
    }
  }
  &-attachments {
    margin-top: 1.2rem;
    &-file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0rem;
      border-bottom: 1px solid $clr-dark-gd4;
      &-details {
        display: flex;
        align-items: center;
        &-image {
          width: 50px;
          height: 25px;
          img {
            width: 100%;
            height: 25px;
          }
        }
        &-name {
          font-family: $font-regular;
          font-size: 0.8rem;
          color: $clr-light-gd3;
          padding-left: 0.4rem;
          word-break: break-all;
        }
      }
      .delete-file {
        opacity: 0.5;
        width: 12px;
        margin-left: 10px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &-footer {
    border-radius: 5px;
    .submit {
      margin-top: 1.2rem;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .create-ticket {
    &-content {
      padding: 10%;
      &-title {
        color: #c1c1c1;
        font-family: $font-regular;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 29px;
        margin-bottom: 20px;
      }
      .charVal {
        font-size: 0.7rem;
        float: right;
        color: #5c5c5d;
        font-family: $font-regular;
      }
      .drop-layout {
        -webkit-appearance: none;
        background: url("../../../assets/icons/down_arrow.svg") no-repeat 98%;
        line-height: 1rem;
        color: #efeff4;
        &:focus {
          background-color: #000000;
        }
      }
      &-upload {
        position: relative;
        margin-top: 1rem;
        .file-input {
          position: absolute;
          opacity: 0;
          z-index: 100;
          width: 28%;
          height: 24px;
        }
        .upload {
          width: 28%;
          padding: 6px 2px;
          color: #5c5c5d;
          font-family: $font-regular;
          font-size: 9px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 11px;
          text-align: center;
          background: $clr-dark-gd1;
          border: 1px solid $clr-popup-label;
          border-radius: 2px;
        }
      }
    }
    &-attachments {
      margin-top: 1.2rem;
      &-file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0rem;
        border-bottom: 1px solid $clr-dark-gd4;
        &-details {
          display: flex;
          align-items: center;
          &-image {
            width: 50px;
            height: 25px;
            img {
              width: 100%;
              height: 25px;
            }
          }
          &-name {
            font-family: $font-regular;
            font-size: 0.8rem;
            color: $clr-light-gd3;
            padding-left: 0.4rem;
          }
        }
        .delete-file {
          opacity: 0.5;
          width: 12px;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    &-footer {
      border-radius: 5px;
      .submit {
        margin-top: 1.2rem;
        width: 100%;
      }
    }
  }
}
</style>
